<template>
  <div class="home-page">
    <breadcrumb-menu :content="content" />

    <div class="container">
      <div class="home-intro">
        <h1 class="home-intro__heading">
          {{ content.properties.heading ? content.properties.heading : content.name }}
        </h1>

        <rich-text
          v-if="content.properties.description"
          class="home-intro__description"
          :content="content.properties.description" />

        <quick-search
          class="home-intro__search"
          :placeholder="content.properties.searchPlaceholder" />
      </div>
    </div>

    <assessments-section />

    <section
      v-if="content.properties.platforms?.length ?? 0 > 0"
      class="platforms-section">
      <div class="container">
        <h2 class="home-page__subheading">
          Platforms
        </h2>

        <div class="row">
          <div
            v-for="(platform, index) in content.properties.platforms"
            :key="index"
            class="col-lg-4">
            <item-card
              class="platforms-section__platform"
              :item="platform"
              :button-label="`Learn about ${platform.name}`" />
          </div>
        </div>
      </div>
    </section>

    <section
      v-if="content.properties.keySupportItems?.length"
      class="key-info-section">
      <div class="container">
        <h2 class="home-page__subheading">
          {{ content.properties.keySupportHeading || "Key Support Information" }}
        </h2>

        <div class="row">
          <div
            v-for="(item, index) in content.properties.keySupportItems"
            :key="index"
            class="col-lg-4">
            <item-card
              :item="item"
              type="home" />
          </div>
        </div>
      </div>
    </section>

    <div class="container">
      <pinned-articles
        :articles="content.properties.pinnedArticles"
        :heading="content.properties.pinnedArticlesHeading"
        heading-class="home-page__subheading"
        :current-content="content" />
    </div>

    <section
      v-if="content.properties.guides?.length"
      class="guides-section">
      <div class="container">
        <h2 class="home-page__subheading">
          Guides
        </h2>

        <div class="row">
          <div
            v-for="(guide, index) in content.properties.guides"
            :key="index"
            class="col-lg-4">
            <item-card
              class="guides-section__guide"
              :item="guide"
              button-label="Read the guide" />
          </div>
        </div>

        <div
          v-if="guideAreaData.data.value"
          class="guides-section__actions">
          <a-button
            :to="guideAreaData.data.value.route.path"
            variant="primary">
            All Guides
          </a-button>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts" setup>
import type { ContentItem } from "@edco/apollo";
import { useContentDeliveryResource } from "#imports";
import type { Home } from "~/models/generated/Home";
import PinnedArticles from "~/components/PinnedArticles.vue";
import BreadcrumbMenu from "~/components/BreadcrumbMenu.vue";
import type { GuideArea } from "~/models/generated/GuideArea";
import AssessmentsSection from "~/components/AssessmentsSection.vue";

defineProps<{
  content: ContentItem<Home>
}>();

const { getByTypeSingle } = useContentDeliveryResource();

const guideAreaData = await getByTypeSingle<GuideArea>( "guideArea" );
</script>

<style lang="scss" scoped>
.home-intro {
  background: #f8f8f8;
  padding: 60px;
  margin-bottom: 60px;
  margin-top: 15px;

  @media ( width <= 991px ) {
    padding: 20px;
  }

  &__heading {
    font-size: 3rem;
    margin-bottom: 20px;

    @media ( width <= 1199px ) {
      font-size: 2.5rem;
    }

    @media ( width <= 991px ) {
      font-size: 2rem;
    }
  }

  &__description {
    font-weight: 500;
    margin-bottom: 20px;
  }

  ::v-deep( .home-intro__search ) {
    width: 100%;
    margin-top: 40px;

    @media ( width <= 1199px ) {
      margin-top: 20px;
    }

    @media ( width <= 991px ) {
      margin-top: 10px;
    }

    input {
      width: 100%;
      padding: 20px;
      border-radius: 10px;
      border: 1px solid #ccc;
    }
  }
}

::v-deep( .home-page__subheading ) {
  font-size: 2.5rem;
  margin-bottom: 40px;
}

.platforms-section {
  background: #f8f8f8;
  padding: 40px 0 30px;

  &__platform {
    margin-bottom: 20px;
  }
}

.key-info-section {
  padding: 40px 0 50px;
}

.pinned-articles-section {
  padding: 40px 0 50px;

  &__heading {
    font-size: 2.5rem;
  }
}

.guides-section {
  background: #f8f8f8;
  padding: 40px 0 50px;

  &__actions {
    margin-top: 20px;

    .btn {
      padding: 10px;

      img {
        display: inline-block;
        margin-left: 15px;
      }
    }
  }

  &__guide {
    margin-bottom: 20px;
  }
}

.box {
  background-color: lightgray;
  padding: 1rem;
  border-radius: 1rem;
  display: flex;
  align-content: center;
  flex-direction: column;
}

.collapse {
  transition: height 2s cubic-bezier( 0.33, 1, 0.68, 1 );
}
</style>
